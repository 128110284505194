<template>
  <section style="overflow: hidden; position: relative;">
    <v-img id="imgBg" src="@assets/images/about_background.png" style="height: 100%; object-fit: none;">
      <br><br><br><br><br><br>

      <!-- The Overlapping Image (HiSolver) -->
      <v-img src="@/assets/images/hisolver.png"
      style="position: absolute; top: 50%; left: 35%; transform: translate(-50%, -50%); z-index: 1; width: 50%; ; height: auto;">
      </v-img>

      <v-container style="position: relative; z-index: 2;">
        <v-row align="left" justify="space-between">

          <!-- Title on the Left -->
          <v-col class="text-left" cols="12" md="4" style="padding-left: 40px;">
            <span class="custom-font" style="color: white; font-weight: 300; font-size: 65px;">
              {{ $t('about_page.title') }}
            </span>
          </v-col>

          <!-- Content on the Right in Transparent Box -->
          <v-col class="text-left mt-8" cols="12" md="7" style="padding-right: 10px;">
            <div
              style="background-color: rgba(188, 136, 254, 0.3); padding: 40px; border-radius: 15px; border: 1px solid white; backdrop-filter: blur(10px);">

              <div class="custom-font" style="color: white; font-size: 2.2em; font-weight: 600; margin-bottom: 15px;">
                {{ $t('about_page.section1.title') }}
              </div>

              <div class="custom-font" style="color: white; font-size: 1.1em; font-weight: 500; line-height: 1.6em;">
                {{ $t('about_page.section1.text') }}
              </div>

              <div class="custom-font" style="color: white; font-size: 1.1em; font-weight: 500; line-height: 1.6em;">
                {{ $t('about_page.section2.text') }}
              </div>

              <div class="custom-font" style="color: white; font-size: 1.1em; font-weight: 500; line-height: 1.6em;">
                {{ $t('about_page.section3.text') }}
              </div>

              <div class="custom-font" style="color: white; font-size: 1.1em; font-weight: 500; line-height: 1.6em;">
                {{ $t('about_page.section4.text') }}
              </div>

              <div class="custom-font" style="color: white; font-size: 1.5em; margin-top: 30px; font-weight: bold;">
                {{ $t('about_page.conclusion.title') }}
              </div>
            </div>
          </v-col>

        </v-row>
      </v-container>

    </v-img>
  </section>
</template>

<script>
export default {
  // Your script code here
}
</script>

<style scoped>
.display-2 {
  font-size: 2.8em;
  font-weight: 700;
}

.custom-font {
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
}
</style>
